import React from 'react';
import Section from '../Section';
import SmallLink from '../SmallLink';
import SpotlightItem from './SpotlightItem';

export const SpotlightsContent = ({ members, content }) => {
  return (
    <Section>
      <div className="flex flex-col items-center justify-center w-full mb-4">
        <p className="mb-2 text-3xl text-left text-black font-baloo sm:text-justify">
          {content.blurb}
        </p>
        <SmallLink item={content.item} />
      </div>
      <div className="flex flex-row flex-wrap items-center justify-around w-full">
        {members.map((item, index) => (
          <SpotlightItem key={index} item={item} />
        ))}
      </div>
    </Section>
  );
};

export default SpotlightsContent;
