import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SpotlightsContent from '../components/Programs/SpotlightsContent';

export const SpotlightsPageTemplate = ({ title, members, content }) => {
  return (
    <div className="flex flex-col w-full bg-s4tk-white">
      <Header title={title} />
      <SpotlightsContent members={members} content={content} />
    </div>
  );
};

const SpotlightsPage = ({ data }) => {
  const members = data.allMarkdownRemark.edges.map(
    (item) => item.node.frontmatter
  );

  const content = data.markdownRemark.frontmatter.content;

  return (
    <Layout>
      <SpotlightsPageTemplate
        title="Meet Our Athletes"
        members={members}
        content={content}
      />
    </Layout>
  );
};

export default SpotlightsPage;

export const spotlightsPageQuery = graphql`
  query SpotlightsPageTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "spotlights-item" } } }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            facts {
              title
              text
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "spotlights-content" } }) {
      frontmatter {
        content {
          blurb
          item {
            title
            icon {
              prefix
              title
            }
            url
          }
        }
      }
    }
  }
`;
