import React, { useState } from 'react';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { motion } from 'framer-motion';

export const SpotlightItem = ({ item, ambassador }) => {
  const [isSelected, setIsSelected] = useState(false);

  const frontVariants = {
    visible: { rotateY: 0, opacity: 1 },
    hidden: { rotateY: -180, opacity: 0 }
  };

  const backVariants = {
    visible: { rotateY: 0, opacity: 1 },
    hidden: { rotateY: 180, opacity: 0 }
  };

  return (
    <motion.div
      onClick={() => setIsSelected(!isSelected)}
      className="relative flex flex-col items-center justify-around mx-4 my-4 bg-white cursor-pointer select-none w-96 h-106"
    >
      <div
        className="absolute z-10 w-full h-full"
        style={{ perspective: '600px' }}
      >
        <motion.div
          className="absolute w-full h-full p-4 bg-s4tk-blue"
          variants={frontVariants}
          initial={false}
          animate={isSelected ? 'hidden' : 'visible'}
          transition={{ duration: 0.5 }}
        >
          <PreviewCompatibleImage
            className="w-full border-4 border-white h-5/6 rounded-tl-3xl rounded-br-3xl"
            imgStyle={{ objectFit: 'cover' }}
            image={item.image}
            alt={item.name}
          />
          <div className="flex flex-col items-center justify-around w-full px-4 h-1/6">
            <p className="text-lg text-center text-white uppercase font-baloo">
              {`Sports 4 The Kids ${ambassador ? 'Ambassador' : 'Athlete'}`}
            </p>
            <div className="flex items-center justify-center w-full py-0 bg-white sm:py-2">
              <p className="text-2xl font-bold font-baloo sm:text-3xl text-s4tk-blue">
                {item.name}
              </p>
            </div>
          </div>
        </motion.div>
      </div>
      <div
        className="absolute z-10 w-full h-full"
        style={{ perspective: '600px' }}
      >
        <motion.div
          className="absolute w-full h-full p-4 bg-s4tk-blue"
          variants={backVariants}
          initial={false}
          animate={isSelected ? 'visible' : 'hidden'}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col items-center justify-start w-full h-full p-4 space-y-4 overflow-y-scroll bg-white">
            {item.facts &&
              item.facts.map((fact, index) => (
                <div
                  key={index}
                  className="flex flex-col items-start justify-start w-full md:flex-row"
                >
                  <div className="flex flex-row items-start justify-center w-full md:w-1/2 md:justify-start">
                    <p className="text-lg font-bold text-center sm:text-xl text-s4tk-blue font-baloo md:text-left">
                      {fact.title}
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-center w-full md:w-1/2 md:justify-end">
                    <p className="text-center text-black text-md sm:text-lg font-baloo md:text-right">
                      {fact.text}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default SpotlightItem;
